export {}

type AppsflyerParams = {
  oneLinkURL: string
  is_retargeting: string
  afParameters: {
    mediaSource: { keys: string[]; defaultValue: string }
    campaign: { keys: string[]; defaultValue: string }
    channel: { keys: string[]; defaultValue: string }
    afSub4: { keys: string[]; defaultValue: string }
  }
}

declare global {
  interface Window {
    AF_SMART_SCRIPT: {
      generateOneLinkURL: (params: AppsflyerParams) => string
    }
  }
}

export const generateOneLinkURL = (): string => {
  const oneLinkURL = 'https://prodstarx.onelink.me/mYMN/'
  const is_retargeting = 'true'

  const mediaSource = { keys: ['utm_source'], defaultValue: 'noMediaSource' }
  const campaign = { keys: ['utm_campaign'], defaultValue: 'noCampaign' }
  const channel = { keys: ['utm_medium'], defaultValue: 'noChannel' }
  const afSub4 = { keys: ['utm_content'], defaultValue: 'noContent' }

  const AF_LINK_RESULT: any = window.AF_SMART_SCRIPT.generateOneLinkURL({
    oneLinkURL,
    is_retargeting,
    afParameters: {
      mediaSource,
      campaign,
      channel,
      afSub4,
    },
  })

  return AF_LINK_RESULT?.clickURL
}
