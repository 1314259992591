import Axios from 'axios'
import { FAQParams, GET_FAQ_API } from '../constants'

export const getFAQs = ({ limit = '', question = '', feature_name = '' }) => {
  // eslint-disable-next-line prefer-const
  let params: FAQParams = {
    apps_code: 'starx',
  }

  limit && (params.limit = limit)
  question && (params.question = question)
  feature_name && (params.type = feature_name)

  return Axios.get(GET_FAQ_API, {
    params: params,
  })
}
