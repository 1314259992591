//#region IMPORT
import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import t from '../../../../lang'
import './FooterPromo.scss'

import { Button } from '../../../../components'
import ButtonAppStore from '../../../../assets/ButtonAppStore.svg'
import ButtonPlayStore from '../../../../assets/ButtonPlayStore.svg'
import PhotoHandSide from '../../../../assets/photo-hand-side.png'
import PhotoHandDown from '../../../../assets/photo-hand-down.png'
import {
  externalLink,
  useWindowDimensions,
  getScreenOrientation,
} from '../../../wrapper/utils'
import { PATH } from '../../../wrapper/constants/TypesWrapper'
import { generateOneLinkURL } from '../../../wrapper/utils/generateOneLink'

//#region MAIN COMPONENT
//#region INTERFACE
type Props = {
  className?: string
  id: string
  title?: any
  description?: any
  type?: 1 | 2
}
//#endregion

const FooterPromo: React.FC<Props> = ({
  className,
  id,
  title,
  description,
  type = 1,
}: Props) => {
  //#region CLASSES
  const classes = classnames('promo__footer', { className })
  //#endregion

  const { width } = useWindowDimensions()
  const orientation = getScreenOrientation('(orientation: portrait)')
  const [isMobile, setIsMobile] = useState(false)
  const [isPortrait, setIsPortrait] = useState(false)

  const initializeResizeWindow = () => {
    window.addEventListener('resize', () => {
      setIsPortrait(orientation)
      const screenWidth = screen.width
      screenWidth <= 767 ? setIsMobile(true) : setIsMobile(false)
    })
  }

  useEffect(() => {
    width < 767 && setIsMobile(true)
    setIsPortrait(orientation)
    initializeResizeWindow()
  }, [])

  return (
    <div id={id} className={classes}>
      <div className={`promo__footer--container ${type == 2 ? 'type-2' : ''}`}>
        <div className="promo__footer--left">
          <h3 className="promo__footer--left__title">{t(title)}</h3>
          <p className="promo__footer--left__description">{t(description)}</p>

          <div className="promo__footer--buttons__container">
            <Button
              elId="ButtonAndroidDownloadApp"
              onClick={() => {
                externalLink(generateOneLinkURL(), 'PLAYSTORE')
              }}
              image={ButtonPlayStore}
            />
            <Button
              elId="ButtonIOSDownloadApp"
              onClick={() => {
                externalLink(generateOneLinkURL(), 'APPSTORE')
              }}
              image={ButtonAppStore}
            />
          </div>
        </div>
        <div className="promo__footer--right">
          {type == 2 && isMobile && isPortrait ? (
            <>
              <img src={PhotoHandDown} />
              <div className="promo__footer--buttons__container--type-2">
                <Button
                  elId="ButtonAndroidDownloadApp"
                  onClick={() => {
                    externalLink(generateOneLinkURL(), 'PLAYSTORE', '', true)
                  }}
                  image={ButtonPlayStore}
                />
                <Button
                  elId="ButtonIOSDownloadApp"
                  onClick={() => {
                    externalLink(generateOneLinkURL(), 'APPSTORE', '', true)
                  }}
                  image={ButtonAppStore}
                />
              </div>
            </>
          ) : (
            <img src={PhotoHandSide} />
          )}
        </div>
      </div>
    </div>
  )
}

export default FooterPromo
