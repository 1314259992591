//#region IMPORT
import React, { ReactNode, useEffect, useState } from 'react'
import './Accordion.scss'
import Collapsible from 'react-collapsible'
import AccordionHeader from './AccordionHeader'
import { mdiChevronDown } from '@mdi/js'
import parse from 'html-react-parser'

interface AccordionProps {
  removedEl?: string
  datas: {
    title: string
    content: string
  }[]
}

export const Accordion: React.FC<AccordionProps> = ({
  removedEl,
  datas,
}: AccordionProps) => {
  const [accordionData, setAccordionData] = useState(datas)
  useEffect(() => {
    setAccordionData(datas)
  }, [datas])

  // used to : Remove any <h2></h2> tag from the string
  const cleanText = (descText: string, removedEl: string) => {
    const newRegex = new RegExp(`<${removedEl}.*?>.*?</${removedEl}>`, 'gi')
    const cleanedText = descText
      .replace(newRegex, '')
      .replaceAll('\n', '<br />')
    return cleanedText
  }

  return (
    <div className="accordion-container">
      {accordionData.length > 0 &&
        accordionData.map((item: any, index: any) => (
          <Collapsible
            key={`collapsible-${index}`}
            open={index == 0 ? true : false}
            trigger={
              <AccordionHeader title={item.title} icon={mdiChevronDown} />
            }>
            {removedEl
              ? parse(cleanText(item.content, removedEl))
              : parse(
                  item.content ? item.content.replaceAll('\n', '<br />') : '-',
                )}
          </Collapsible>
        ))}
    </div>
  )
}

interface NestedAccordionProps {
  label?: string
  title: string
  index: number
  children: ReactNode | string
  isLoaded?: boolean
  onHeaderClick?: (value: string) => void
}

export const NestedAccordion: React.FC<NestedAccordionProps> = ({
  label,
  title,
  index,
  children,
  onHeaderClick,
}: NestedAccordionProps) => {
  const handleHeaderClick = (value: string) => {
    if (onHeaderClick) {
      onHeaderClick(value)
    }
  }
  return (
    <div className="nested-accordion-container">
      <Collapsible
        className="nested-accordion-item"
        key={`collapsible-${index}`}
        open={index == 0}
        trigger={
          <AccordionHeader
            onHeaderClick={(value) => {
              handleHeaderClick(value)
            }}
            label={label}
            title={title}
            icon={mdiChevronDown}
          />
        }>
        {children}
      </Collapsible>
    </div>
  )
}
