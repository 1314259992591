import React from 'react'
import Icon from '@mdi/react'

interface HeaderProps {
  onHeaderClick?: (value: string) => void
  label?: string
  title: string
  icon: string
}

const AccordionHeader = ({
  title,
  label,
  icon,
  onHeaderClick,
}: HeaderProps): React.ReactElement => {
  const handleHeaderClick = (value: string) => {
    if (onHeaderClick) {
      onHeaderClick(value)
    }
  }
  return (
    <div
      className="accordion-header"
      onClick={() => {
        handleHeaderClick(label || '')
      }}>
      <span className="accordion-header-title">{title}</span>
      <span className="accordion-header-icon">
        {<Icon path={icon} size={1} />}
      </span>
    </div>
  )
}

export default AccordionHeader
