//#region IMPORT
import React from 'react'
import { useHistory } from 'react-router-dom'
import Icon from '@mdi/react'
import { mdiHome } from '@mdi/js'
import './Breadcrumb.scss'
//#endregion

//#region INTERFACE
type Props = {
  className?: string
  listPages?: any
  separator?: any
}

export interface BreadcrumbInterface {
  title: string
  link: string
  is_active: boolean
}

//#endregion

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const Breadcrumb = ({ listPages, separator }: Props) => {
  const history = useHistory()
  const redirectUser = (link: any) => {
    link && history.push(link)
    return true
  }

  return (
    <div className="breadcrumb-container">
      <Icon path={mdiHome} size={1} />
      {listPages &&
        listPages.map((page: any, index: number) => (
          <span key={`breadcrumb-link-container-${index}`}>
            <span
              onClick={() => {
                redirectUser(page.link)
              }}
              className={`${page.is_active ? 'active' : ''}`}>
              {page.title}
            </span>
            {index != listPages.length - 1 && (
              <Icon
                key={`breadcrumb-separator-${index}`}
                path={separator}
                size={1}
              />
            )}
          </span>
        ))}
    </div>
  )
}
