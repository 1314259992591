//#region IMPORT
import React from 'react'
import Icon from '@mdi/react'
import { mdiMagnify, mdiClose } from '@mdi/js'

import './SearchBar.scss'
import parse from 'html-react-parser'
import { SearchBarProps, SuggestionItemsProps } from './constant'
//#endregion

const highlightKeyword = (sentence = '', keyword = '') => {
  const words = keyword.split(' ')
  let highlightedSentence = sentence

  words.forEach((word) => {
    const regex = new RegExp(`(${word})`, 'gi')
    highlightedSentence = highlightedSentence.replace(regex, '<b>$1</b>')
  })

  return highlightedSentence
}

const SuggestionItems = ({
  keyword,
  data,
  onItemClick,
}: SuggestionItemsProps) => {
  const handleClick = (data: any) => {
    if (onItemClick) {
      onItemClick(data)
    }
  }
  return (
    <>
      {data.map((item: any, index: number) => (
        <div
          key={`suggestion-${index}`}
          className="suggestion-item"
          // the input in searchbar has onBlur event, which when onBlur event
          // is called, it will set the showSuggestions state to false.
          // suggestion bar is being hidden immediately when we click
          // the suggestion item, then we can use onMouseDown because
          // onBouseDown is triggered before onBlur event.
          onMouseDown={() => {
            handleClick(item)
          }}>
          {parse(highlightKeyword(item.title, keyword))}
        </div>
      ))}
    </>
  )
}

export const SearchBar = ({
  placeholder,
  showSuggestions,
  suggestionsData,
  suggestionsEmptyText,
  seeAllSearchText,
  isLoadSearch,
  keyword,
  handleOnInput,
  handleSuggestionClick,
  handleOnSeeAllSuggestions,
  handleOnClear,
  handleOnBlur,
  handleOnFocus,
  handleOnKeyDown,
}: SearchBarProps) => {
  const renderSuggestionContent = () => {
    if (isLoadSearch) {
      return <p>Sedang melakukan pencarian. . .</p>
    } else if (suggestionsData && suggestionsData.length > 0) {
      return (
        <SuggestionItems
          keyword={keyword}
          data={suggestionsData}
          onItemClick={(data) => {
            handleSuggestionClick && handleSuggestionClick(data)
          }}
        />
      )
    } else {
      return <p>{suggestionsEmptyText}</p>
    }
  }

  return (
    <div className="search-bar__container">
      <span className="search-bar__search-icon">
        <Icon path={mdiMagnify} size={1} />
      </span>
      {keyword && (
        <span
          className="search-bar__clear-icon"
          onClick={() => {
            handleOnClear && handleOnClear()
          }}>
          <Icon path={mdiClose} size={1} />
        </span>
      )}

      <input
        value={keyword}
        type="text"
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          handleOnKeyDown && handleOnKeyDown(e)
        }}
        onFocus={(e: React.ChangeEvent<HTMLInputElement>) => {
          handleOnFocus && handleOnFocus(e)
        }}
        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
          handleOnBlur && handleOnBlur(e)
        }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          handleOnInput && handleOnInput(e)
        }}
        placeholder={placeholder}
      />
      {showSuggestions && (
        <div className="search-bar__suggestions">
          <div className="search-bar__suggestions--content">
            {renderSuggestionContent()}
          </div>
          {suggestionsData && suggestionsData.length > 0 && !isLoadSearch && (
            <div
              className="search-bar__suggestions--footer"
              onMouseDown={() => {
                handleOnSeeAllSuggestions && handleOnSeeAllSuggestions(keyword)
              }}>
              {seeAllSearchText}
            </div>
          )}
        </div>
      )}
    </div>
  )
}
