import React, { useState, useEffect, useCallback } from 'react'

import { useDocumentTitle, useWindowDimensions } from '../../../wrapper/utils'
import { Header } from '../../../wrapper/components/Header'
import { SearchBar } from '../../../wrapper/components/SearchBar'
import FooterPromo from '../../../promo/components/FooterPromo'

import { FAQList, FAQListMobile } from '../../components/FAQList'
import { FEATURE_LIST, FAQListInterface } from '../../constants'
import { getFAQs } from '../../utils/api'
import {
  formatFeatureList,
  formatFAQ,
  formatSearchData,
  eliminateHtmlTags,
} from '../../utils/formatting'
import { SuggestionProps } from '../../../wrapper/components/SearchBar/constant'

import t from '../../../../lang'
import { mdiChevronRight } from '@mdi/js'
import {
  Breadcrumb,
  BreadcrumbInterface,
} from '../../../wrapper/components/BreadCrumb'

import './FAQ.scss'

const FAQ: React.FC = () => {
  useDocumentTitle(t('StarX - Pertanyaan yang Sering Diajukan'))
  const [breadcrumbList, setBreadcrumbList] = useState<BreadcrumbInterface[]>(
    [],
  )

  const { width } = useWindowDimensions()
  const [isMobile, setIsMobile] = useState(false)
  useEffect(() => {
    width < 1024 ? setIsMobile(true) : setIsMobile(false)
  })

  const [isLoading, setIsLoading] = useState(false)
  const [faqList, setFAQList] = useState<FAQListInterface[]>([])
  const [searchedFAQList, setSearchedFAQList] = useState<FAQListInterface[]>([])
  const [displayedFAQ, setDisplayedFAQ] = useState<FAQListInterface>()

  const [searchKeyword, setSearchKeyword] = useState('')
  const [showSearchSuggestions, setShowSearchSuggestions] = useState(false)
  const [isSearchMode, setIsSearchMode] = useState(false)
  const [isLoadSearch, setIsLoadSearch] = useState(false)
  const [searchData, setSearchData] = useState<SuggestionProps[]>([])

  const [displayedListFeature, setDisplayedListFeature] = useState(FEATURE_LIST)
  const [selectedListFeature, setSelectedListFeature] = useState('danatunai')
  const [syncedListFeature, setSyncedListFeature] = useState<string[]>([])

  const [key, setKey] = useState(0)
  const resetComponent = () => {
    setKey((prevKey) => prevKey + 1)
  }

  useEffect(() => {
    window.scrollTo(0, 0)

    setBreadcrumbList([
      {
        title: 'Home',
        link: '/',
        is_active: false,
      },
      {
        title: t('Pertanyaan yang Sering Ditanyakan'),
        link: '',
        is_active: true,
      },
    ])

    FAQListFetch({ feature_name: selectedListFeature })
  }, [])

  /**
   * Called when page is first loaded/reset
   */
  const FAQListFetch = useCallback(async ({ feature_name = '' }) => {
    setSyncedListFeature((prevState) => [...prevState, feature_name])
    setIsLoading(true)

    const response = await getFAQs({ feature_name })
    const formattedFAQ = formatFAQ(response.data['data'])
    if (formattedFAQ.length > 0) {
      setFAQList((prevState) => [...prevState, formattedFAQ[0]])
    }

    setDisplayedFAQ(formattedFAQ[0])
    setIsLoading(false)
  }, [])

  useEffect(() => {
    setIsLoadSearch(true)

    const delaySearch = setTimeout(async () => {
      if (searchKeyword) {
        const searchFAQ = await getFAQs({
          limit: searchKeyword ? '3' : '',
          question: searchKeyword,
        })

        if (searchFAQ) {
          const getSearchData = searchFAQ.data['data']
          const formattedSearchData = formatSearchData(getSearchData)
          setSearchData(formattedSearchData)
        }
      }

      setIsLoadSearch(false)
    }, 500)

    return () => clearTimeout(delaySearch)
  }, [searchKeyword])

  const clearSuggestion = () => {
    setIsLoading(true)
    setSearchKeyword('')
    setIsSearchMode(false)
    resetComponent()

    setDisplayedListFeature(FEATURE_LIST)

    setTimeout(() => {
      setIsLoading(false)
    }, 200)
  }

  /**
   * To handle actions to do when user click one of the suggestion
   * below the search bar
   * @param data
   */
  const displayDetailSuggestion = (data: any) => {
    setIsLoading(true)
    isMobile && resetComponent()

    const formattedFAQ = formatFAQ([data])
    setSearchedFAQList(formattedFAQ)

    const formattedFeatureList = formatFeatureList(formattedFAQ)
    setDisplayedListFeature(formattedFeatureList)

    setIsSearchMode(true)
    setShowSearchSuggestions(false)
    setIsLoading(false)
  }

  const displayAllSuggestion = async (keyword: string) => {
    setSearchKeyword(keyword)
    setShowSearchSuggestions(false)
    setIsSearchMode(true)
    setIsLoading(true)

    const matchedFAQ = await getFAQs({ question: searchKeyword })

    if (matchedFAQ) {
      isMobile && resetComponent()

      const getMatchedData = matchedFAQ.data['data']
      const formattedSearchData = formatFAQ(getMatchedData)
      setSearchedFAQList(formattedSearchData)

      const formattedFeatureList = formatFeatureList(formattedSearchData)
      setDisplayedListFeature(formattedFeatureList)
    }

    setIsLoading(false)
  }

  const handleSelectedFeatureChange = (newSelection: string) => {
    setSelectedListFeature(newSelection)

    !syncedListFeature.includes(newSelection)
      ? FAQListFetch({ feature_name: newSelection })
      : displaySyncedFAQList(newSelection)
  }

  const displaySyncedFAQList = (feature_name: string) => {
    const displayedFAQ = faqList.find((item) => {
      return item.type == feature_name
    })

    setDisplayedFAQ(displayedFAQ)
  }

  return (
    <div className="faq">
      <Header.DefaultHeader
        className="faq__header"
        title={t('Pertanyaan yang Sering Diajukan')}
        description="StarXperience"></Header.DefaultHeader>

      <div className="faq__container">
        <Breadcrumb
          listPages={breadcrumbList}
          separator={mdiChevronRight}></Breadcrumb>

        <h3>Punya Pertanyaan Seputar Star Xperience?</h3>
        <SearchBar
          placeholder="Cari solusi permasalahan Anda disini"
          showSuggestions={showSearchSuggestions}
          suggestionsData={searchData}
          isLoadSearch={isLoadSearch}
          keyword={searchKeyword}
          handleOnBlur={() => {
            setShowSearchSuggestions(false)
          }}
          handleOnKeyDown={(event) => {
            if (searchKeyword && event.key === 'Enter') {
              displayAllSuggestion(searchKeyword)
              resetComponent()
            } else if (event.key === 'Backspace' && searchKeyword.length <= 1) {
              clearSuggestion()
            }
          }}
          handleOnFocus={() => {
            searchKeyword && setShowSearchSuggestions(true)
          }}
          handleOnClear={() => {
            clearSuggestion()
          }}
          handleSuggestionClick={(data) => {
            displayDetailSuggestion(data)
          }}
          handleOnSeeAllSuggestions={(keyword) => {
            displayAllSuggestion(keyword)
          }}
          suggestionsEmptyText="Tidak ditemukan FAQ dengan Keyword yang Anda cari"
          handleOnInput={(event) => {
            const inputVal = eliminateHtmlTags(event.target.value)
            setSearchKeyword(inputVal)
            setShowSearchSuggestions(inputVal.length > 0)

            setSearchData([])
          }}
          seeAllSearchText="Lihat hasil lainnya"
        />

        {!isMobile && !isSearchMode ? (
          <div className="faq__list-container">
            <FAQList
              autoScrollIntoSelectedTab={true}
              selectedTab={selectedListFeature}
              onSelectedFeatureChange={handleSelectedFeatureChange}
              tabList={FEATURE_LIST}
              datas={displayedFAQ}
              isLoading={isLoading}
            />
          </div>
        ) : (
          <div className="faq__list-container--mobile">
            {/* This component uses key so it can be re-initialized when user do a search/reset search */}
            <FAQListMobile
              key={key}
              onParentAccordionClick={(value) => {
                !syncedListFeature.includes(value) &&
                  !isSearchMode &&
                  FAQListFetch({ feature_name: value })
              }}
              isLoading={isLoading}
              isAllLoaded={isSearchMode}
              accordionList={displayedListFeature}
              datas={isSearchMode ? searchedFAQList : faqList}
            />
          </div>
        )}
      </div>

      <FooterPromo
        type={2}
        id="promo__footer"
        title={t('Apalagi yang Anda tunggu?')}
        description={t(
          'Segera jadi bagian dari StarXperience dan dapatkan banyaknya manfaat yang dapat menunjang kebutuhan hidup Anda.',
        )}></FooterPromo>
    </div>
  )
}

export default FAQ
