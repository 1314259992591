import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { Env } from '../../../../config/Config'

import './UpdateProfile.scss'

type Prop = {
  token: string
}

const UPDATE_PROFILE_API = `${Env.api}/customer/customers/customer/profiles/non_dsf/token`

const UpdateProfile: React.FC = () => {
  const envName = process.env.REACT_APP_ENV
  const { token } = useParams<Prop>()

  const [redirectLink, setRedirectLink] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [isFound, setIsFound] = useState(true)
  const [verificationTitle, setVerificationTitle] = useState('')
  const [verificationMessage, setVerificationMessage] = useState(
    'Silahkan klik tombol di bawah ini untuk kembali ke aplikasi dan melakukan login',
  )

  const verifyUpdateProfile = async ({ token }: Prop) => {
    const res = await axios
      .post(`${UPDATE_PROFILE_API}/${token}`, { token: token })
      .catch((err) => {
        // Any errors (i.e: Data Customer Not Found, Expired Token, etc) is returned as 'error response status 400' from the API.
        // It will be treated as error by Axios, so we need to handle it within the 'catch' block
        setIsLoading(false)

        const errMessage = err.response.data.message
        setVerificationMessage(errMessage)

        if (errMessage.includes('batas waktu')) {
          envName == 'production'
            ? setRedirectLink('https://prodstarx.onelink.me/mYMN/profile')
            : setRedirectLink('https://qastarx.onelink.me/Xtay/profile')
        } else if (
          errMessage.includes('Dokumen riwayat perubahan tidak ditemukan')
        ) {
          setIsFound(false)
        }
      })

    if (res) {
      setIsLoading(false)
      const status = res.status

      if (status == 200) {
        setVerificationTitle('Verifikasi Berhasil!')
      } else {
        setVerificationTitle('Verifikasi Gagal')
      }
    } else {
      setVerificationTitle('Verifikasi Gagal')
    }
  }

  useEffect(() => {
    envName == 'production'
      ? setRedirectLink('https://prodstarx.onelink.me/mYMN/renewphonenumber')
      : setRedirectLink('https://qastarx.onelink.me/Xtay/renewphonenumber')

    verifyUpdateProfile({
      token: token,
    })
  }, [])

  return (
    <div className="update-profile">
      <div className="update-profile__container">
        {isLoading ? (
          <p>Sedang melakukan verifikasi. . .</p>
        ) : (
          <>
            <h2>{verificationTitle}</h2>
            <p>{verificationMessage}</p>

            {isFound && (
              <button
                onClick={() => {
                  window.open(redirectLink)
                  setTimeout(() => {
                    envName == 'production'
                      ? setRedirectLink(
                          'https://prodstarx.onelink.me/mYMN/profile',
                        )
                      : setRedirectLink(
                          'https://qastarx.onelink.me/Xtay/profile',
                        )
                  }, 200)
                }}
                className="button">
                Buka Aplikasi StarXperience
              </button>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default UpdateProfile
