import {
  FEATURE_LIST_ENUM,
  FAQListInterface,
  FAQItemInterface,
} from '../constants'

import { SuggestionProps } from '../../wrapper/components/SearchBar/constant'

/**
 * To format RAW FAQ data from API into FAQListInterface format
 * @param data
 * @returns
 */
export const formatFAQ = (data: any) => {
  // eslint-disable-next-line prefer-const
  let formattedData: FAQListInterface[] = []

  // eslint-disable-next-line prefer-const
  let retrievedType: any = []

  if (data?.length > 0) {
    data.map((item: any, index: number) => {
      // eslint-disable-next-line prefer-const
      let faqByType: { type: string; data: FAQItemInterface[] } = {
        type: '',
        data: [],
      }

      const d = {
        id: item.id,
        title: item.title ?? item.question,
        content: item.content ?? item.answer,
      }

      if (!retrievedType.includes(item.type)) {
        retrievedType.push(item.type)

        faqByType.type = item.type
        faqByType.data.push(d)

        formattedData.push(faqByType)
      } else {
        const idx = formattedData.findIndex((fData: any) => {
          return fData.type == item.type
        })

        formattedData[idx].data.push(d)
      }
    })
  }

  return formattedData
}

/**
 * To format FAQs that is retrieved from Search action
 * @param data
 * @returns
 */
export const formatSearchData = (data: any) => {
  // eslint-disable-next-line prefer-const
  let formattedSearchData: SuggestionProps[] = []
  if (data?.length > 0) {
    data.map((item: any, index: number) => {
      const d = {
        id: item.id,
        title: item.question,
        content: item.answer,
        type: item.type,
      }

      formattedSearchData.push(d)
    })
  }

  return formattedSearchData
}

type FeatureType = keyof typeof FEATURE_LIST_ENUM
export const formatFeatureList = (data: FAQListInterface[]) => {
  // eslint-disable-next-line prefer-const
  let featureList: any = []

  data.map((item: any) => {
    const itemType: FeatureType = item.type
    const d = {
      type: itemType,
      label: FEATURE_LIST_ENUM[itemType],
    }

    featureList.push(d)
  })

  return featureList
}

/**
 * To eliminate/remove HTML tags from given string. |
 * Example ->
 * Sample text: <h1>Test</h1>
 * Result: Test
 * @param text
 * @returns
 */
export const eliminateHtmlTags = (text = '') => {
  text = text.replace(/&lt;/g, '<').replace(/&gt;/g, '>')

  const regex = /<[^>]*>/g
  const result = text.replace(regex, '')
  return result
}
