//#region IMPORT
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Axios from 'axios'

import { Header } from '../../../homepage/components'
import { Breadcrumb } from '../../../homepage/components/Breadcrumb'
import PromoBannerInfo from '../../components/PromoBannerInfo'
import FooterPromo from '../../components/FooterPromo'
import { PromoTabs, PromoTabsSkeleton } from '../../components/PromoTabs'
import Accordion from '../../components/Accordion'
import OtherPromo from '../../components/OtherPromo'
import { Button } from '../../../../components'
import t from '../../../../lang'
import './DetailPromo.scss'
import { Promo, PromoList, PROMO_API } from '../../constants'
import { externalLink, useWindowDimensions } from '../../../wrapper/utils'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { Env } from '../../../../config/Config'
import { PATH } from '../../../wrapper/constants'
import { mdiChevronRight } from '@mdi/js'
import { useDocumentTitle } from '../../../wrapper/utils'
import { generateOneLinkURL } from '../../../wrapper/utils/generateOneLink'

interface HeaderInterface {
  title: string
  desc: string
}

interface PromoContentProps {
  title: string
  content: string
}

interface BreadcrumbInterface {
  title: string
  link: any
  is_active: boolean
}

const getPromoId = (url: any) => {
  const currUrl = url.split('/')[2]
  const getId = currUrl.split('-').at(-1)
  return getId
}

const DetailPromo: React.FC = () => {
  const [breadcrumbList, setBreadCrumbList] = useState<BreadcrumbInterface[]>(
    [],
  )

  const { width } = useWindowDimensions()
  const history = useHistory()
  const [isMobile, setIsMobile] = useState(false)
  const [ajukanLink, setAjukanLink] = useState<any>()
  const [linkType, setLinkType] = useState()

  const [idPromo, setIdPromo] = useState(getPromoId(history.location.pathname))
  const [promoContent, setPromoContent] = useState<PromoContentProps[]>([])
  const [promo, setPromo] = useState<Promo>()

  const [otherPromo, setOtherPromo] = useState<PromoList[]>([])
  const [totalOtherPromo, setTotalOtherPromo] = useState(0)

  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingOtherPromo, setIsLoadingOtherPromo] = useState(false)
  const [headerContent, setHeaderContent] = useState<HeaderInterface>({
    title: '',
    desc: '',
  })

  const getDetailPromo = (id: any) => {
    return Axios.get(`${Env.api}/blog/promo/${id}`)
  }

  const getOtherPromo = (excludeId: any) => {
    return Axios.get(PROMO_API, {
      params: {
        limit: 3,
        offset: 0,
        status: 'published',
        exclude_id: excludeId,
      },
    })
  }

  const generatePromoTypeHeaderTitle = (promoType = '') => {
    let title = ''
    if (promoType == 'mobil_penumpang_baru') {
      title = 'Promo Mobil Penumpang Baru'
    } else if (promoType == 'mobil_niaga_baru') {
      title = 'Promo Mobil Niaga baru'
    } else if (promoType == 'dana_tunai') {
      title = 'Promo Dana Tunai'
    } else if (promoType == 'modal_usaha') {
      title = 'Promo Modal Usaha'
    }

    return title
  }

  const updateHeader = (dataHeader: string, promoType = '') => {
    let headerTitle = ''
    let headerDesc = ''
    if (promoType) {
      headerTitle = generatePromoTypeHeaderTitle(promoType)
      headerDesc =
        dataHeader.split(' - ')[0].length > 128
          ? dataHeader.split(' - ')[0].substring(0, 128) + '..'
          : dataHeader.split(' - ')[0]
    } else {
      headerTitle =
        dataHeader.split(' - ')[0].length > 128
          ? dataHeader.split(' - ')[0].substring(0, 128) + '..'
          : dataHeader.split(' - ')[0]
      headerDesc = dataHeader.split(' - ')[1] ? dataHeader.split(' - ')[1] : ''
    }
    setHeaderContent({
      title: headerTitle,
      desc: headerDesc,
    })

    setBreadCrumbList([
      {
        title: 'Home',
        link: '/',
        is_active: false,
      },
      {
        title: 'Promo',
        link: '/promo',
        is_active: false,
      },
      {
        title: `${dataHeader.split(' - ')[0]}`,
        link: '',
        is_active: true,
      },
    ])
  }

  const fetchDetailPromo = useCallback(async () => {
    setIsLoading(true)
    setIsLoadingOtherPromo(true)
    const response = await getDetailPromo(getPromoId(history.location.pathname))

    // cek if promo isn't deleted or promo is still active
    if (!response.data['data'] || response.data['data']['status'] == 'draft') {
      history.push(PATH.PAGE_404)
      return false
    }
    setPromo(response.data['data'])
    updateHeader(
      response.data['data']['title'],
      response.data['data']['promo_type'],
    )
    setPromoContent([
      {
        title: 'Description',
        content: response.data['data']['description'],
      },
      {
        title: 'Terms & Conditions',
        content: response.data['data']['terms_and_conditions'],
      },
    ])

    // set other promo
    const getOther = await getOtherPromo(getPromoId(history.location.pathname))
    setOtherPromo(getOther.data['data'])
    setTotalOtherPromo(getOther.data['record_count'])

    // set hyperlink
    if (response['data']) {
      setLinkType(response.data['data']['link_type'])

      response.data['data']['link_type'] == 'internal'
        ? setAjukanLink(generateOneLinkURL())
        : setAjukanLink(response.data['data']['hyperlink'])
    }

    setIsLoading(false)
    setIsLoadingOtherPromo(false)
  }, [history.location.pathname])

  useEffect(() => {
    setIsMobile(false)
    width < 992 && setIsMobile(true)

    setIdPromo(getPromoId(history.location.pathname))
    if (idPromo && typeof idPromo !== 'undefined') {
      fetchDetailPromo()
    }
  }, [history.location.pathname])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useDocumentTitle(headerContent.title)
  const seeAll = () => {
    history.push('/promo')
  }

  return (
    <div className="detail-promo">
      <Header.Promo
        className="detail-promo-header"
        title={headerContent.title}
        isLoading={isLoading}
        isMobile={isMobile}
        description={headerContent.desc}></Header.Promo>

      <div className="detail-promo-container">
        {breadcrumbList ? (
          <Breadcrumb
            key="asd"
            listPages={breadcrumbList}
            separator={mdiChevronRight}></Breadcrumb>
        ) : (
          <Skeleton width={isMobile ? 300 : 1000} />
        )}

        <main>
          {!isLoading ? (
            <section className="content--left">
              {!isMobile ? (
                <PromoTabs datas={promoContent} />
              ) : (
                isMobile && <Accordion datas={promoContent} />
              )}
            </section>
          ) : (
            <section className="content--left">
              <PromoTabsSkeleton />
            </section>
          )}
          <aside className="content--right">
            <PromoBannerInfo
              is_loading={isLoading}
              image_url={promo?.image_url}
              post_images={promo?.post_images}
              branch_area={promo?.branch_area}
              start_date={promo?.start_date}
              end_date={promo?.end_date}
              is_mobile={isMobile}
              ajukan_link={ajukanLink}
              link_type={linkType}
            />
          </aside>
        </main>

        <OtherPromo
          isLoading={isLoadingOtherPromo}
          otherPromoData={otherPromo}
          totalOtherPromo={totalOtherPromo}
        />

        {totalOtherPromo >= 4 && (
          <button
            type="button"
            className="see-all__link--mobile"
            onClick={() => {
              seeAll()
            }}>
            Lihat Semua Promo
          </button>
        )}
      </div>

      {ajukanLink && (
        <div className="button-ajukan--mobile">
          <Button
            elId="buttonAjukan"
            variant="contained"
            fullWidth={true}
            className="promo-banner-info__open-app"
            text="AJUKAN DALAM APLIKASI"
            color="tertiary"
            onClick={() => {
              externalLink(ajukanLink, 'deep-link-redirect')
            }}
          />
        </div>
      )}

      <FooterPromo
        type={2}
        id="promo__footer"
        title={t('Apalagi yang Anda tunggu?')}
        description={t(
          'Segera jadi bagian dari StarXperience dan dapatkan banyaknya manfaat yang dapat menunjang kebutuhan hidup Anda.',
        )}></FooterPromo>
    </div>
  )
}

export default DetailPromo
