import React, { useEffect, useRef, useState } from 'react'
import { Tab, Tabs } from 'mui-tabs'
import {
  Accordion,
  NestedAccordion,
} from '../../../wrapper/components/Accordion'

import { FAQListProps, FAQListMobileProps } from '../../constants'
import Skeleton from 'react-loading-skeleton'
import './FAQList.scss'

interface AccordionDataProps {
  title: string
  content: string
}

export const FAQList: React.FC<FAQListProps> = ({
  autoScrollIntoSelectedTab = false,
  selectedTab,
  onSelectedFeatureChange,
  tabList,
  isLoading,
  datas,
}: FAQListProps) => {
  const [selectedType, setSelectedType] = useState(selectedTab)
  const handleSelectedFeatureChange = (newVal: any) => {
    setSelectedType(newVal)
    onSelectedFeatureChange(newVal)
  }

  // eslint-disable-next-line prefer-const
  let formattedData: AccordionDataProps[] = []
  const data = datas?.data
  data?.map((item) => {
    const d = {
      title: item.title,
      content: item.content,
    }

    formattedData.push(d)
  })

  const containerRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (autoScrollIntoSelectedTab) {
      const selectedTab = containerRef.current?.querySelector('.tab-selected')
      if (selectedTab) {
        selectedTab.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center',
        })
      }
    }
  }, [selectedTab])

  return (
    <>
      <div className="faq-list__tabs-container">
        <p>List Feature</p>
        <Tabs
          ref={containerRef}
          value={selectedType}
          onChange={(value) => handleSelectedFeatureChange(value)}>
          {tabList.length > 0 &&
            tabList.map((item, index) => (
              <Tab
                key={`tab-${index}`}
                value={item.type}
                label={item.label}
                selected={item.type == selectedType}
              />
            ))}
        </Tabs>
      </div>

      <div className="faq-list__accordion-container">
        {isLoading ? (
          <div className="faq-list__accordion-skeleton-container">
            <Skeleton count={1} height={50} />
            <Skeleton count={1} height={50} />
            <Skeleton count={1} height={50} />
            <Skeleton count={1} height={50} />
          </div>
        ) : formattedData.length > 0 ? (
          <Accordion datas={formattedData} />
        ) : (
          <p className="text-center">Belum ada FAQ untuk Feature ini</p>
        )}
      </div>
    </>
  )
}

export const FAQListMobile: React.FC<FAQListMobileProps> = ({
  onParentAccordionClick,
  accordionList,
  isLoading = false,
  isAllLoaded = false,
  datas,
}: FAQListMobileProps) => {
  const handleParentAccordionHeaderClick = (clickedAccordionVal: any) => {
    if (onParentAccordionClick) {
      onParentAccordionClick(clickedAccordionVal)
    }
  }

  const renderNestedAccordionChildren = (
    isLoading: boolean,
    formattedData: any,
  ) => {
    if (isLoading) {
      return (
        <>
          <Skeleton count={1} height={50} />
          <Skeleton count={1} height={50} />
          <Skeleton count={1} height={50} />
          <Skeleton count={1} height={50} />
        </>
      )
    } else if (formattedData && formattedData.length > 0) {
      return <Accordion datas={formattedData} />
    } else {
      return <p className="text-center">Belum ada FAQ untuk Feature ini</p>
    }
  }

  const getFAQByFeatureName = (feature_name: string) => {
    const displayedFAQ = datas?.find((item) => {
      return item.type == feature_name
    })

    return displayedFAQ
  }

  const formatFAQ = (faqData: any) => {
    // eslint-disable-next-line prefer-const
    let formattedData: AccordionDataProps[] = []
    const data = faqData?.data

    data?.map((item: any) => {
      const d = {
        title: item.title,
        content: item.content,
      }

      formattedData.push(d)
    })

    return formattedData
  }

  const displayFAQByFeatureName = (feature_name: string) => {
    const getFAQ = getFAQByFeatureName(feature_name)
    const formattedFAQ = formatFAQ(getFAQ)

    return formattedFAQ
  }

  return (
    <>
      {accordionList.length > 0 &&
        accordionList.map((item, index) => (
          <NestedAccordion
            key={index}
            label={item.type}
            title={item.label}
            onHeaderClick={(value) => {
              !isAllLoaded && handleParentAccordionHeaderClick(value)
            }}
            index={index}>
            {renderNestedAccordionChildren(
              isLoading,
              displayFAQByFeatureName(item.type),
            )}
          </NestedAccordion>
        ))}
    </>
  )
}
