import { Env } from '../../../config/Config'

export const GET_FAQ_API = `${Env.api}/blog/faq`
export interface FAQParams {
  limit?: string
  type?: string
  apps_code: string
  question?: string
}

export const FEATURE_LIST_ENUM = {
  danatunai: 'Dana Tunai',
  kontrak_saya: 'Kontrak Saya',
  mobil_bekas: 'Mobil Bekas',
  mobil_baru: 'Mobil Baru',
  jual_mobil: 'Jual Mobil',
  reservasi_bpkb: 'Reservasi BPKB',
  reward_claim: 'Reward',
  profil: 'Profil',
  modal_usaha: 'Modal Usaha',
  cek_harga_mobil: 'Cek Harga Mobil',
  perpanjang_stnk: 'Perpanjang STNK',
  asuransi: 'Asuransi',
  general_website: 'General Website',
  kendaraan_saya: 'Mobil Saya',
}

export const FEATURE_LIST = [
  {
    label: 'Dana Tunai',
    type: 'danatunai',
  },
  {
    label: 'Modal Usaha',
    type: 'modal_usaha',
  },
  {
    label: 'Mobil Saya',
    type: 'kendaraan_saya',
  },
  {
    label: 'Mobil Baru',
    type: 'mobil_baru',
  },
  {
    label: 'Mobil Bekas',
    type: 'mobil_bekas',
  },
  {
    label: 'Kontrak Saya',
    type: 'kontrak_saya',
  },
  {
    label: 'Perpanjang STNK',
    type: 'perpanjang_stnk',
  },
  {
    label: 'Reservasi BPKB',
    type: 'reservasi_bpkb',
  },
  {
    label: 'Reward',
    type: 'reward_claim',
  },
  {
    label: 'Jual Mobil',
    type: 'jual_mobil',
  },
  {
    label: 'Cek Harga Mobil',
    type: 'cek_harga_mobil',
  },
  {
    label: 'Asuransi',
    type: 'asuransi',
  },
  {
    label: 'General Website',
    type: 'general_website',
  },
  {
    label: 'Profil',
    type: 'profil',
  },
]

export interface FAQListInterface {
  type: string
  data: {
    id: number
    title: string
    content: string
  }[]
}

export interface FAQItemInterface {
  id: number
  title: string
  content: string
}

export interface FAQListProps {
  autoScrollIntoSelectedTab?: boolean
  selectedTab: string
  onSelectedFeatureChange: any
  isLoading: boolean
  tabList: {
    label: string
    type: string
  }[]
  datas?: {
    type: string
    data: {
      id: number
      title: string
      content: string
    }[]
  }
}

export interface FAQListMobileProps {
  onParentAccordionClick?: (value: string) => void
  isLoading?: boolean
  isAllLoaded?: boolean
  accordionList: {
    label: string
    type: string
  }[]
  datas?: {
    type: string
    data: {
      id: number
      title: string
      content: string
    }[]
  }[]
}
