//#region IMPORT
import React from 'react'
import { useHistory } from 'react-router'
import classnames from 'classnames'
import t from '../../../../lang'
import './Footer.scss'
import Icon from '@mdi/react'
import {
  mdiFacebook as IconFacebook,
  mdiInstagram as IconInstagram,
  mdiYoutube as IconYoutube,
  mdiWeb as IconWebsite,
} from '@mdi/js'
import LogoDipo from '../../../../assets/logo_dipostar_new_invert.png'
import { PATH } from '../../constants'
import { externalLink } from '../../utils'
//#endregion

//#region MAIN COMPONENT
//#region INTERFACE
type Props = {
  className?: string
  id?: string
  description?: string
}
//#endregion

const Footer: React.FC<Props> = ({ className, id }: Props) => {
  //#region CLASSES
  const classes = classnames('footer', { className })
  //#endregion

  const history = useHistory()
  const sosmedList = [
    {
      icon: IconYoutube,
      link: PATH.YOUTUBE,
      label: 'YOUTUBE',
    },
    {
      icon: IconWebsite,
      link: PATH.WEBSITE,
      label: 'WEBSITE',
    },
    {
      icon: IconFacebook,
      link: PATH.FACEBOOK,
      label: 'FACEBOOK',
    },
    {
      icon: IconInstagram,
      link: PATH.INSTAGRAM,
      label: 'INSTAGRAM',
    },
  ]

  return (
    <div id={id} className={classes}>
      <div className="footer-item footer-item--row logo-dipo-wrapper">
        <div className="footer-item__label-logo logo-dipo-container">
          <img src={LogoDipo} alt="Logo Dipo Star" />
        </div>
        <div className="footer-item footer-item__text">
          <p>Sentral Senayan 2, 3rd Floor</p>
          <p>Jl. Asia Afrika No. 8 Senayan Jakarta 10270</p>
        </div>
      </div>
      <div className="footer-item footer-item--row">
        <div className="footer-item--row__inner-container">
          <div className="footer-item__label">{t('Contact Halo DSF')}</div>
          <div className="footer-item footer-item__contact">
            <a target="_blank" href="tel:08041235235" rel="noreferrer">
              Tel. 0804-1-235235
            </a>
            <a
              target="_blank"
              href="https://wa.me/6281380058850"
              rel="noreferrer">
              WA. +62 813-8005-8850
            </a>
            <a
              target="_blank"
              href="mailto:halodsf@dipostar.com"
              rel="noreferrer">
              Email: halodsf@dipostar.com
            </a>
          </div>
        </div>
      </div>
      <div className="footer-item footer-item--row">
        <div className="footer-item--row__inner-container">
          <div className="footer-item__label">Info</div>
          <div className="footer-item footer-item__contact">
            <p
              className="footer-item__text-link"
              onClick={() => history.push(PATH.PRIVACY_POLICY)}>
              {t('Kebijakan Privasi')}
            </p>
            <p
              className="footer-item__text-link"
              onClick={() => history.push(PATH.FAQ)}>
              FAQ
            </p>
          </div>

          <p className="d-md-none mt-3">
            {t(
              'PT Dipo Star Finance is licensed and supervised by the Financial Services Authority',
            )}
          </p>
        </div>
      </div>
      <div className="footer-extend">
        <div className="footer-extend__inner">
          <div>
            <div className="footer-extend__text">
              <span>Powered by Dipo Star Finance (DSF). </span>
              <span>Copyright &copy; 2021</span>
            </div>
            <div className="footer-extend__text d-sm-none">
              {t(
                'PT Dipo Star Finance is licensed and supervised by the Financial Services Authority',
              )}
            </div>
          </div>
          <div className="footer-extend__socmed-container">
            {sosmedList.map((item, index) => (
              <div
                key={`sosmed-item-${index}`}
                className="footer-item__link"
                onClick={() => externalLink(item.link, item.label)}>
                <Icon path={item.icon} size={1.35} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
//#endregion
